import React from "react";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, List, ListItem, ListItemIcon } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { RUPEE } from "../components/common/GlobalVariable";
import Button from "@mui/material/Button";
import { useMediaQuery, Alert } from "@mui/material";
import { keyframes } from "@emotion/react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

// import StarIcon from "@mui/icons-material/StarBorder";
import { styled } from "@mui/system";
// import TooltipComponent from "../components/common/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
// import { indexNameFromKeys } from "@aws-amplify/datastore/lib-esm/util";

const StyledButton = styled(Button)`
  display: "none";
  variant: "contained";
  @media (max-width: 600px) {
    display: "block";
  }
`;
const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

function Pricing() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const CalculateButtonValue = useFeatureFlag("Calculate_Button_text");
  const MultiUs_Price_Plan = useFeatureFlag("MultiUs_Price_Plan");
  const referralDiscount = useFeatureFlag("referralsDiscount");
  const Experienced_plan1_year_to_10_years = useFeatureFlag("Experienced_plan1_year_to_10_years");
  const Experienced_10_years = useFeatureFlag("Experienced_10_years");
  const Base_price = useFeatureFlag("Base_price");
  const FirstTimeFree = useFeatureFlag("FirstTimeFree");
  const navigate = useNavigate();
  const Environment = process.env.REACT_APP_ENV;

  function OffValue(off) {
    let discount = ((Base_price - off) * 100) / Base_price;
    return discount.toFixed(2);
  }

  const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

  return (
    <React.Fragment>
      {Environment === "prod" ? (
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16506783500"></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'AW-16506783500');
          `}
          </script>
        </Helmet>
      ) : (
        ""
      )}
      <Box>
        <Header />
      </Box>

      <Box>
        <Container maxWidth="xl" p={20} sx={{ mt: 12 }}>
          <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 4, pb: 4 }}>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              fontWeight="bold"
              css={{
                animation: `${fadeIn} 0.5s ease-in-out`,
              }}
            >
              Pricing
            </Typography>
          </Container>        
          <Grid
            container
            spacing={6}
            alignItems="flex-start"
            justifyContent="center"
            display="flex"
          >
            {/* free plan card */}
            <Grid
              item
              xs={12}
              // sm={tier.title === "Enterprise" ? 12 : 6}
              md={4}
              lg={3}
            >
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  border: "1px solid #e0e0e0",
                  borderRadius: "10px",
                  boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                  transition: "box-shadow 0.3s ease-in-out",
                  "&:hover": {
                    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                  },
                  animation: `${fadeUp} 0.5s ease-in-out`,
                  transform: `scale(1)`,
                  zIndex: 100,
                }}
              >
                <CardHeader
                  title={"Freshers Software Professional"}
                  // subheader={tier.subheader}
                  titleTypographyProps={{
                    align: "center",
                    variant: "h4",
                  }}
                  // action={tier.type === "Multi-Use" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                    color: "#fff",
                  }}
                  sx={{
                    backgroundColor: "#26801E",
                    color: "#fff",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                />
                <CardContent
                  sx={{
                    flexGrow: 1,
                    backgroundColor: "#eafae8",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box pb={1}>
                    <Typography
                      component="h2"
                      variant="subtitle1"
                      color="text.primary"
                      textAlign="center"
                    >
                      Try
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h1"
                      color="text.primary"
                      sx={{ fontWeight: "bold" }}
                    >
                      Free
                    </Typography>
                  </Box>
                  <List>
                    {["Full Access to TechWorth result online"]?.map((line, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          {/* {line === "PDF certificate (via email)" ? (
                              <CancelIcon
                                sx={{ color: "#EF4F38" }}
                                fontSize="large"
                              />
                            ) : ( */}
                          <CheckCircleIcon sx={{ color: "#6cba65" }} fontSize="large" />
                          {/* )} */}
                        </ListItemIcon>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          key={line}
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {line}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>

                {isMobile && (
                  <CardActions
                    sx={{
                      justifyContent: "center",
                      p: 2,
                      borderTop: "1px solid #e0e0e0",
                    }}
                  >
                    <StyledButton
                      variant="contained"
                      onClick={() => navigate("/skills-calculation")}
                    >
                      {CalculateButtonValue}
                    </StyledButton>
                  </CardActions>
                )}
              </Card>
            </Grid>
            {/* Experienced paln card (1 year to 10 years) */}
            {Experienced_plan1_year_to_10_years > 0 ? (
              <Grid
                item
                xs={12}
                // sm={tier.title === "Enterprise" ? 12 : 6}
                md={4}
                lg={3}
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    border: "1px solid #e0e0e0",
                    borderRadius: "10px",
                    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                    },
                    animation: `${fadeUp} 0.5s ease-in-out`,
                    transform: `scale(1)`,
                    zIndex: 100,
                  }}
                >
                  <CardHeader
                    title={"Experienced Software Professional"}
                    subheader={"(1 year to 10 years)"}
                    titleTypographyProps={{
                      align: "center",
                      variant: "h4",
                    }}
                    // action={tier.type === "Multi-Use" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                      color: "#fff",
                    }}
                    sx={{
                      backgroundColor: "#26801E",
                      color: "#fff",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  />
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      backgroundColor: "#eafae8",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                  {FirstTimeFree ? 
                  (<Box>
                      <Typography
                        textAlign="center"
                        component="h2"
                        variant="subtitle1"
                        color="text.primary"
                      >
                        Try first time for
                      </Typography>
                    </Box>) : ("")}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        // mb: 2,
                      }}
                    >
                      {FirstTimeFree ? (<Typography
                        component="h2"
                        variant="h1"
                        color="text.primary"
                        sx={{ fontWeight: "bold" }}
                      >
                        Free
                      </Typography>) : ("")}
                      {FirstTimeFree ? (<Typography>and thereafter</Typography>) : ("")}
                      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} p={0.5}>
                        &nbsp;
                        <Typography variant="h4" sx={{ textDecoration: "line-through" }}>
                          {RUPEE.format(Base_price)}
                        </Typography>
                        &nbsp;&nbsp;
                      </Box>
                      <Box display={"flex"} flexDirection={"row"} justifyContent={"space-around"}>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {RUPEE.format(Experienced_plan1_year_to_10_years)}
                        </Typography>
                        &nbsp;&nbsp;
                        <Typography variant="h5" sx={{ color: "red", fontWeight: "bold" }}>
                          ({OffValue(Experienced_plan1_year_to_10_years)}% OFF)
                        </Typography>
                      </Box>
                    </Box>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon sx={{ color: "#6cba65" }} fontSize="large" />
                        </ListItemIcon>
                        <Typography display={"flex"} flexDirection={"column"}>
                          {/* <Typography
                            variant="subtitle1"
                            sx={{ textDecoration: "line-through" }}
                          >
                            {RUPEE.format(Base_price)}
                          </Typography> */}
                          {/* &nbsp; */}
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            {RUPEE.format(Experienced_plan1_year_to_10_years)} / per use
                          </Typography>
                        </Typography>
                      </ListItem>
                      {referralDiscount > 0 && (
                        <ListItem>
                          <ListItemIcon>
                            <CheckCircleIcon sx={{ color: "#6cba65" }} fontSize="large" />
                          </ListItemIcon>
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            Refer 1 person and get additional discount &nbsp;
                            {RUPEE.format(referralDiscount)}
                          </Typography>
                        </ListItem>
                      )}
                      {["Full Access to TechWorth result online"]?.map((line, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <CheckCircleIcon sx={{ color: "#6cba65" }} fontSize="large" />
                          </ListItemIcon>
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            key={line}
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            {line}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>

                  {isMobile && (
                    <CardActions
                      sx={{
                        justifyContent: "center",
                        p: 2,
                        borderTop: "1px solid #e0e0e0",
                      }}
                    >
                      <StyledButton
                        variant="contained"
                        onClick={() => navigate("/skills-calculation")}
                      >
                        {CalculateButtonValue}
                      </StyledButton>
                    </CardActions>
                  )}
                </Card>
              </Grid>
            ) : (
              ""
            )}

            {/* Experienced paln card (> 10 years) */}
            {Experienced_10_years > 0 ? (
              <Grid
                item
                xs={12}
                // sm={tier.title === "Enterprise" ? 12 : 6}
                md={4}
                lg={3}
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    border: "1px solid #e0e0e0",
                    borderRadius: "10px",
                    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                    },
                    animation: `${fadeUp} 0.5s ease-in-out`,
                    transform: `scale(1)`,
                    zIndex: 100,
                  }}
                >
                  <CardHeader
                    title={"Experienced Software Professional"}
                    subheader={"(more than 10 years)"}
                    titleTypographyProps={{
                      align: "center",
                      variant: "h4",
                    }}
                    // action={tier.type === "Multi-Use" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                      color: "#fff",
                    }}
                    sx={{
                      backgroundColor: "#26801E",
                      color: "#fff",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  />
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      backgroundColor: "#eafae8",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    {FirstTimeFree ? (<Box>
                      <Typography
                        textAlign="center"
                        component="h2"
                        variant="subtitle1"
                        color="text.primary"
                      >
                        Try first time for
                      </Typography>
                    </Box>) : ("")}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        // mb: 2,
                      }}
                    >
                     {FirstTimeFree ? (<Typography
                        component="h2"
                        variant="h1"
                        color="text.primary"
                        sx={{ fontWeight: "bold" }}
                      >
                        Free
                      </Typography>) : ("")}
                      {FirstTimeFree ? (<Typography>and thereafter</Typography>) : ("")}
                      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} p={0.5}>
                        &nbsp;
                        <Typography variant="h4" sx={{ textDecoration: "line-through" }}>
                          {RUPEE.format(Base_price)}
                        </Typography>
                        <br />
                      </Box>
                      <Box display={"flex"} flexDirection={"row"} justifyContent={"space-around"}>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {RUPEE.format(Experienced_10_years)}
                        </Typography>
                        &nbsp;&nbsp;
                        <Typography variant="h5" sx={{ color: "red", fontWeight: "bold" }}>
                          ({OffValue(Experienced_10_years)}% OFF)
                        </Typography>
                      </Box>
                    </Box>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleIcon sx={{ color: "#6cba65" }} fontSize="large" />
                        </ListItemIcon>
                        <Typography display={"flex"} flexDirection={"column"}>
                          {/* <Typography
                            variant="subtitle1"
                            sx={{ textDecoration: "line-through" }}
                          >
                            {RUPEE.format(Base_price)}
                          </Typography> */}
                          {/* &nbsp; */}
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            {RUPEE.format(Experienced_10_years)} / per use
                          </Typography>
                        </Typography>
                      </ListItem>
                      {referralDiscount > 0 && (
                        <ListItem>
                          <ListItemIcon>
                            <CheckCircleIcon sx={{ color: "#6cba65" }} fontSize="large" />
                          </ListItemIcon>
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            Refer 1 person and get additional discount &nbsp;
                            {RUPEE.format(referralDiscount)}
                          </Typography>
                        </ListItem>
                      )}
                      {["Full Access to TechWorth result online"]?.map((line, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <CheckCircleIcon sx={{ color: "#6cba65" }} fontSize="large" />
                          </ListItemIcon>
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            key={line}
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            {line}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>

                  {isMobile && (
                    <CardActions
                      sx={{
                        justifyContent: "center",
                        p: 2,
                        borderTop: "1px solid #e0e0e0",
                      }}
                    >
                      <StyledButton
                        variant="contained"
                        onClick={() => navigate("/skills-calculation")}
                      >
                        {CalculateButtonValue}
                      </StyledButton>
                    </CardActions>
                  )}
                </Card>
              </Grid>
            ) : (
              ""
            )}

            {/* Multi-Us paln card */}
            {MultiUs_Price_Plan > 0 ? (
              <Grid
                item
                xs={12}
                // sm={tier.title === "Enterprise" ? 12 : 6}
                md={4}
                lg={3}
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    border: "1px solid #e0e0e0",
                    borderRadius: "10px",
                    boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                    },
                    animation: `${fadeUp} 0.5s ease-in-out`,
                    transform: `scale(1)`,
                    zIndex: 100,
                  }}
                >
                  <CardHeader
                    title={"Multi-Use"}
                    subheader={"On Demand*"}
                    titleTypographyProps={{
                      align: "center",
                      variant: "h4",
                    }}
                    // action={tier.type === "Multi-Use" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                      color: "#fff",
                    }}
                    sx={{
                      backgroundColor: "#26801E",
                      color: "#fff",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  />
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      backgroundColor: "#eafae8",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box pb={1}>
                      <Typography
                        textAlign="center"
                        component="h2"
                        variant="subtitle1"
                        color="text.primary"
                      >
                        Try first time
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        mb: 2,
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h1"
                        color="text.primary"
                        sx={{ fontWeight: "bold" }}
                      >
                        Free
                      </Typography>
                      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} p={0.5}>
                        <Typography>up to</Typography> &nbsp;
                        <Typography variant="h4" sx={{ textDecoration: "line-through" }}>
                          {RUPEE.format(Base_price)}
                        </Typography>
                        &nbsp;&nbsp;
                        <Typography variant="h5">{RUPEE.format(MultiUs_Price_Plan)}</Typography>
                      </Box>

                      <Typography variant="h5" sx={{ color: "red", fontWeight: "bold" }}>
                        {OffValue(MultiUs_Price_Plan)}% OFF
                      </Typography>
                    </Box>
                    <List>
                      {[
                        "Pay Per Year",
                        referralDiscount < 0
                          ? ""
                          : `Refer 1 person and get ${RUPEE.format(referralDiscount)} OFF`,
                        "Full Access to TechWorth result online",
                        "PDF certificate (via email)",
                      ]?.map((line, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <CheckCircleIcon sx={{ color: "#6cba65" }} fontSize="large" />
                          </ListItemIcon>
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            key={line}
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            {line}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                    <ul>
                      {[
                        "Paid access to Coaches",
                        "2 Sessions (4 hours) Workshop - In Person Advisory **",
                      ]?.map((line, index) => (
                        <Typography
                          key={index}
                          variant="subtitle1"
                          align="center"
                          color="text.secondary"
                          sx={{
                            mb: 1,
                            fontStyle: "italic",
                          }}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>

                  {isMobile && (
                    <CardActions
                      sx={{
                        justifyContent: "center",
                        p: 2,
                        borderTop: "1px solid #e0e0e0",
                      }}
                    >
                      <StyledButton
                        variant="contained"
                        onClick={() => navigate("/skills-calculation")}
                      >
                        {CalculateButtonValue}
                      </StyledButton>
                    </CardActions>
                  )}
                </Card>
              </Grid>
            ) : (
              ""
            )}

            {/* {MultiUse_Price_Plan === true
              ? onDemand?.map(
                  (tier, index) => (
                    <Grid
                      order={index + 1}
                      item
                      key={tier.type}
                      xs={12}
                      sm={tier.title === "Enterprise" ? 12 : 6}
                      md={4}
                      lg={3}
                    >
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          border: "1px solid #e0e0e0",
                          borderRadius: "10px",
                          boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                          transition: "box-shadow 0.3s ease-in-out",
                          "&:hover": {
                            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                          },
                          animation: `${fadeUp} 0.5s ease-in-out`,
                          transform: `scale(1)`,
                          zIndex: 100,
                        }}
                      >
                        <CardHeader
                          title={tier.type}
                          subheader={tier.subheader}
                          titleTypographyProps={{
                            align: "center",
                            variant: "h4",
                          }}
                          action={
                            tier.type === "Multi-Use" ? <StarIcon /> : null
                          }
                          subheaderTypographyProps={{
                            align: "center",
                            color: "#fff",
                          }}
                          sx={{
                            backgroundColor: "#26801E",
                            color: "#fff",
                            borderBottom: "1px solid #e0e0e0",
                          }}
                        />
                        <CardContent
                          sx={{
                            flexGrow: 1,
                            backgroundColor: "#eafae8",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box textAlign="center" pb={1}>
                            <Typography
                              component="h2"
                              variant="subtitle1"
                              color="text.primary"
                            >
                              Try first time 
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              mb: 2,
                            }}
                          >
                            <Typography
                              component="h2"
                              variant="h1"
                              color="text.primary"
                              sx={{ fontWeight: "bold" }}
                            >
                              {tier.min === "Free"
                                ? tier.min
                                : RUPEE.format(tier.min)}{" "}
                              <Typography
                                variant="subtitle1"
                                textAlign={"center"}
                              >
                                Up to
                              </Typography>
                            </Typography>

                            <Typography
                              variant="h4"
                              color="text.secondary"
                              pt={1}
                            >
                              {RUPEE.format(tier.max)} /- ***
                            </Typography>
                          </Box>
                          <List>
                            {tier.description.map((line) => (
                              <ListItem>
                                <ListItemIcon>
                                  <CheckCircleIcon
                                    sx={{ color: "#6cba65" }}
                                    fontSize="large"
                                  />
                                </ListItemIcon>
                                <Typography
                                  component="li"
                                  variant="subtitle1"
                                  color="text.primary"
                                  key={line}
                                  sx={{
                                    mb: 1,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {line}
                                </Typography>
                              </ListItem>
                            ))}
                          </List>
                          <ul>
                            {tier.advisory?.map((line) => (
                              <Typography
                                component="li"
                                variant="subtitle1"
                                align="center"
                                color="text.secondary"
                                key={line}
                                sx={{
                                  mb: 1,
                                  fontStyle: "italic",
                                }}
                              >
                                {line}
                              </Typography>
                            ))}
                          </ul>
                        </CardContent>

                        {isMobile && (
                          <CardActions
                            sx={{
                              justifyContent: "center",
                              p: 2,
                              borderTop: "1px solid #e0e0e0",
                            }}
                          >
                            <StyledButton variant="contained"
                            onClick={() => navigate("/skills-calculation")}
                             >
                              {CalculateButtonValue}
                            </StyledButton>
                          </CardActions>
                        )}
                      </Card>
                    </Grid>
                  )
                  // Enterprise card is full width at sm breakpoint
                )
              : pricingPlan?.map(
                  (tier, index) => (
                    <Grid
                      order={index + 1}
                      item
                      key={tier.type}
                      xs={12}
                      sm={tier.title === "Enterprise" ? 12 : 6}
                      md={4}
                      lg={4}
                    >
                      <Card
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          border: "1px solid #e0e0e0",
                          borderRadius: "10px",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          transition: "box-shadow 0.3s ease-in-out",
                          "&:hover": {
                            boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
                            animation: `${fadeUp} 0.5s ease-in-out`,
                          },
                        }}
                      >
                        <CardHeader
                          title={tier.type}
                          subheader={tier.subheader}
                          titleTypographyProps={{
                            align: "center",
                            variant: "h4",
                          }}
                          action={
                            tier.type === "Multi-Use" ? <StarIcon /> : null
                          }
                          subheaderTypographyProps={{
                            align: "center",
                            color: "#fff",
                          }}
                          sx={{
                            backgroundColor: "#26801E",
                            color: "#fff",
                            borderBottom: "1px solid #e0e0e0",
                          }}
                        />
                        <CardContent
                          sx={{
                            flexGrow: 1,
                            backgroundColor: "#fafafa",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box textAlign="center" pb={1}>
                            <Typography
                              component="h2"
                              variant="subtitle1"
                              color="text.primary"
                            >
                              Try first time 
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              mb: 2,
                            }}
                          >
                            <Typography
                              component="h2"
                              variant="h1"
                              color="text.primary"
                              sx={{ fontWeight: "bold" }}
                            >
                              {tier.min === "Free"
                                ? tier.min
                                : RUPEE.format(tier.min)}{" "}
                              <Typography
                                variant="subtitle1"
                                textAlign={"center"}
                              >
                                Up to
                              </Typography>
                            </Typography>

                            <Typography
                              variant="h4"
                              color="text.secondary"
                              pt={1}
                            >
                              {RUPEE.format(tier.max)} /- ***
                            </Typography>
                          </Box>
                          <List>
                            {tier.description.map((line) => (
                              <ListItem>
                                <ListItemIcon>
                                  <CheckCircleIcon
                                    sx={{ color: "#26801E" }}
                                    fontSize="large"
                                  />
                                </ListItemIcon>
                                <Typography
                                  component="li"
                                  variant="subtitle1"
                                  color="text.primary"
                                  key={line}
                                  sx={{
                                    mb: 1,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {line}
                                </Typography>
                              </ListItem>
                            ))}
                          </List>
                          <ul>
                            {tier.advisory?.map((line) => (
                              <Typography
                                component="li"
                                variant="subtitle1"
                                align="center"
                                color="text.secondary"
                                key={line}
                                sx={{
                                  mb: 1,
                                  fontStyle: "italic",
                                }}
                              >
                                {line}
                              </Typography>
                            ))}
                          </ul>
                        </CardContent>

                        {isMobile && (
                          <CardActions
                            sx={{
                              justifyContent: "center",
                              p: 2,
                              borderTop: "1px solid #e0e0e0",
                            }}
                          >
                            <StyledButton variant="contained"
                            onClick={() => navigate("/skills-calculation")}
                            >
                              {CalculateButtonValue}
                            </StyledButton>
                          </CardActions>
                        )}
                      </Card>
                    </Grid>
                  )
                  // Enterprise card is full width at sm breakpoint
                )} */}
          </Grid>
        </Container>
        <Typography textAlign="center" p={4}>
          <Link href="/refund-policy">Refund policy</Link>
        </Typography>
      </Box>
      <Box sx={{ py: 2 , m:"0 auto", display:"flex", justifyContent:"center", }}>
          <Alert severity="warning">You pay full price in <b>Incognito Mode</b>.</Alert>
      </Box>
      <Box sx={{position:{lg:"fixed", sm:"unset", xs:"unset", md:"fixed", xl:"fixed"}, bottom:"0px", width:"100%"}}>
        <Footer />
      </Box>
    </React.Fragment>
  );
}

export default Pricing;
