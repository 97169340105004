import {
  Autocomplete,
  Box,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Chip,
  Avatar,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { getOptions, getSkillOptions } from "../stepper.helper";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import "../../../styles.css";
import React, { useEffect, useRef, useState } from "react";
import TooltipComonent from "../../common/Tooltip";
import _ from "lodash";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import axios from "axios";
import PrimarySkillRelevanceAI from "./PrimarySkillRelevanceAI";
import { setPrimarySkillRelevance } from "../../../redux/action/result";
import { useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";

function SkillMultiSelectQuestionComponent({ question }) {

  const getIconBySkillName = (skillName) => {
    const svgIconPath = `../../../assets/images/icons/${skillName}.svg`;
    const pngIconPath = `../../../assets/images/icons/${skillName}.png`;

    // Check if the SVG icon exists
    const svgExists = imageExists(svgIconPath);
    if (svgExists) {
      return svgIconPath;
    }
    // Check if the PNG icon exists
    const pngExists = imageExists(pngIconPath);
    if (pngExists) {
      return pngIconPath;
    }
    // Return null if neither SVG nor PNG exists
    return null;
  };

  // Helper function to check if an image exists
  const imageExists = (url) => {
    const img = new Image();
    img.src = url;
    return img.complete && img.naturalHeight !== 0;
  };

  const {
    control,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useFormContext();
  const staticRandomEncodeDecodeString = useFeatureFlag("staticRandomEncodeDecodeString");
  const secretEncodeDecodeString = useFeatureFlag("secretEncodeDecodeString");
  const dispatch = useDispatch();
  // Data Encryption function
  function DataEncryption(json) {
    const jsonString = JSON.stringify(json);
    const customStringJson = secretEncodeDecodeString + jsonString + secretEncodeDecodeString;
    const newCustomStringJson = customStringJson.replace(/"/g, staticRandomEncodeDecodeString);
    const encodedString = btoa(newCustomStringJson);
    return encodedString;
  }
  function DataDecryption(jsonString) {
    const decodedString = atob(jsonString);
    const getOldStringOne = decodedString.replace(new RegExp(secretEncodeDecodeString, "g"), "");
    const str = staticRandomEncodeDecodeString;
    const escapedString = str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const regex = new RegExp(escapedString, "g");
    const getOldStringTwo = getOldStringOne.replace(regex, '"');
    const json = JSON.parse(getOldStringTwo);
    return json;
  }

  const [open, setOpen] = useState(false); // if dropdown open?
  const [autoValue, setAutoValue] = useState(getValues(question.questionName) || []); // if dropdown open?
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    setValue(question.questionName, autoValue);
  }, [autoValue]);
  const [relevanceResult, setRelevanceResult] = useState([]);
  const [relevanceLoader, setRelevanceLoader] = useState(true);
  const [duplicateError, setDuplicateError] = useState(false);
  const [duplicateMessage, setDuplicateMessage] = useState("Duplicate skill found and removed");
  const [skillSearchDisable, setSkillSearchDisable] = useState(false);
  const shouldRunEffect = useRef(true);
  const cancelSkillToken = useRef(null);

  function removeFirstArrayElementsFromResponse(firstArray, responseArray) {
    const namesToRemove = firstArray.map((item) => item.name);
    return responseArray.filter((item) => !namesToRemove.includes(item.name));
  }

  useEffect(() => {
    if (!shouldRunEffect.current) {
      shouldRunEffect.current = true;
      return;
    }
    if (autoValue.length !== 0) {
      setDuplicateError(false);
      if (cancelSkillToken.current) {
        // console.log("cancelSkillToken", cancelSkillToken.current);
        setRelevanceLoader(true);

        cancelSkillToken.current.cancel("Previous request cancelled");
      }
      setRelevanceLoader(true);
      // add loader for secondary skill search
      if (question.questionName === "secondarySkill") {
        setLoading(true);
      }
      const primarySelectedSkills = getValues("primarySkill");
      // console.log("autoValue123", autoValue);
      try {
        cancelSkillToken.current = axios.CancelToken.source();
        setSkillSearchDisable(true);
        axios
          .post(
            process.env.REACT_APP_API_BASE_URL + "/get-topSkillValidity",
            DataEncryption({
              primarySkill:
                question.questionName === "primarySkill"
                  ? autoValue
                  : [...autoValue, ...primarySelectedSkills],
            }),
            {
              cancelToken: cancelSkillToken.current.token,
              options: {
                type: "json",
              },
            }
          )
          .then((object) => {
            const data = DataDecryption(object.data);
            console.log(data, "Data relevance");
            const topN = (arr, n) => {
              if (n > arr?.length) {
                return arr;
              }
              return arr
                ?.slice()
                ?.sort((a, b) => {
                  // Prioritize chat_gpt true, then sort by estYear
                  if (a.chat_gpt === b.chat_gpt) {
                    return b.expiryYear - a.expiryYear;
                  }
                  return b.chat_gpt - a.chat_gpt;
                })
                .slice(0, n);
            };

            let topSkill = topN(data.topPrimarySkill, 1);
            console.log("topSkill", topSkill);
            if (question.questionName === "primarySkill") {
              dispatch(setPrimarySkillRelevance(topSkill));
              setRelevanceResult(topSkill);
              setRelevanceLoader(false);
            }

            // Find the index of the last duplicate skill
            let lastDuplicateIndex = -1;
            data.topPrimarySkill.forEach((skill, index) => {
              if (skill.isDuplicate) {
                lastDuplicateIndex = index;
              }
            });

            // Remove the last duplicate skill if found
            if (lastDuplicateIndex !== -1) {
              setDuplicateError(true);
              // console.error("Duplicate skill found and removed");
              if (question.questionName === "primarySkill") {
                shouldRunEffect.current = false;
                const filterSkills = data.topPrimarySkill.filter(
                  (item) => item.isDuplicate === false
                );
                // data.topPrimarySkill.pop();
                setAutoValue(filterSkills);
                setRelevanceResult(filterSkills);
                setSkillSearchDisable(false);
                dispatch(setPrimarySkillRelevance(topSkill));
                setDuplicateMessage("Duplicate skill found and removed");
              } else {
                const secondarySkill = getValues("secondarySkill");
                setDuplicateMessage("Similar skill already added into primary skills");
                shouldRunEffect.current = false;
                secondarySkill.pop();
                setAutoValue(secondarySkill);
                setSkillSearchDisable(false);
                if (question.questionName === "secondarySkill") {
                  setLoading(false);
                }
              }
              const AlertDuplicate = setTimeout(() => {
                setDuplicateError(false);
              }, 2000);
              return () => clearTimeout(AlertDuplicate);
            } else {
              if (question.questionName === "primarySkill") {
                shouldRunEffect.current = false;

                setAutoValue(data.topPrimarySkill);
                setSkillSearchDisable(false);
              } else {
                shouldRunEffect.current = false;

                const primarySelectedSkills = getValues("primarySkill");
                // const selectedSkillNames = new Set(
                //   primarySelectedSkills.map((skill) => skill.name.toLowerCase())
                // );
                const updatedData = removeFirstArrayElementsFromResponse(
                  primarySelectedSkills,
                  data.topPrimarySkill
                );
                setAutoValue(updatedData);
                setSkillSearchDisable(false);
                if (question.questionName === "secondarySkill") {
                  setLoading(false);
                }
              }
            }
          })
          .catch((error) => {
            const year = new Date().getFullYear();
            shouldRunEffect.current = false;
            const updatedValue = {
              name: autoValue[autoValue?.length - 1]["name"] || "",
              chat_gpt: false,
              estYear: 2010,
              expiryYear: year + 1,
              score: 70,
            };
            if (autoValue.length > 1) {
              autoValue.pop();
              shouldRunEffect.current = false;

              setRelevanceResult([...autoValue, updatedValue]);
              setRelevanceLoader(false);
              setAutoValue([...autoValue, updatedValue]);
              setSkillSearchDisable(false);
              if (question.questionName === "secondarySkill") {
                setLoading(false);
              }
              // console.log([...autoValue, updatedValue], "updatedValue");
            } else if (autoValue.length === 1) {
              const newAutoValue = autoValue.pop();
              shouldRunEffect.current = false;
              setRelevanceResult([updatedValue]);
              setRelevanceLoader(false);
              setAutoValue([updatedValue]);
              setSkillSearchDisable(false);
              // console.log([newAutoValue, updatedValue], "updatedValue");
              if (question.questionName === "secondarySkill") {
                setLoading(false);
              }
            }
            setRelevanceLoader(false);
            if (question.questionName === "secondarySkill") {
              setLoading(false);
            }
          });
      } catch (error) {
        // setRelevanceResult([]);
        setRelevanceLoader(false);
        setSkillSearchDisable(false);
        if (question.questionName === "secondarySkill") {
          setLoading(false);
        }
      }
    }
  }, [autoValue]);

  const filter = createFilterOptions({
    // Use a custom stringify function to filter based on specific properties
    stringify: (option) => option.name, // Modify this to suit your data structure
    // Limit the number of displayed options in the autocomplete dropdown
    // limit: 50,
  });

  function GetBackGroundColor(skillDemand, type) {
    if (skillDemand === "Trending") {
      switch (type) {
        case "y":
          return "#278C37";
        case "tech":
          return "#278C37";
        case "mgmt":
          return "#30B044";
        case "management":
          return "#30B044";
        case "generic":
          return "#38C94E";
        default:
          return "#278C37";
      }
    }
    if (skillDemand === "Obsolete") {
      switch (type) {
        case "y":
          return "#028EDF";
        case "tech":
          return "#028EDF";
        case "mgmt":
          return "#09A2FA";
        case "management":
          return "#09A2FA";
        case "generic":
          return "#79CDFD";
        default:
          return "#028EDF";
      }
    }
    if (skillDemand === "In demand") {
      switch (type) {
        case "y":
          return "#E9B112";
        case "tech":
          return "#E9B112";
        case "mgmt":
          return "#FEC113";
        case "management":
          return "#FEC113";
        case "generic":
          return "#FAD15D";
        default:
          return "#E9B112";
      }
    }
    if (skillDemand === "Getting outdated fast") {
      switch (type) {
        case "y":
          return "#C60015";
        case "tech":
          return "#C60015";
        case "mgmt":
          return "#E2031B";
        case "management":
          return "#E2031B";
        case "generic":
          return "#FF465A";
        default:
          return "#C60015";
      }
    }
  }

  function checkYear(year) {
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForOutdatedSkill = 4;
    const minYearForCurrentSkill = 1;

    if (year > currentYear) {
      return {
        label: `Trending `,
        barPrimaryColor: "green",
        // barSecondaryColor: "#A5D6A7",
        barSecondaryColor: "#278C37",

        // color: "#26801E",
        color: "#58f374",
      };
    } else if (year >= currentYear - minYearForCurrentSkill) {
      return {
        label: ` In demand`,
        barPrimaryColor: "darkorange",
        // barSecondaryColor: "#ffcc80",
        barSecondaryColor: "#E9B112",
        // color: "darkorange",
        color: "#ffd560",
      };
    } else if (year >= currentYear - minYearForOutdatedSkill) {
      return {
        label: `Getting outdated fast`,
        barPrimaryColor: "#ffc107",
        // barSecondaryColor: "#fff59d",
        barSecondaryColor: "#C60015",

        // color: "#FF9D09",
        color: "#f74861",
      };
    } else if (year <= currentYear - minYearDiff) {
      return {
        label: `Obsolete `,
        barPrimaryColor: "red",
        // barSecondaryColor: "#FFCDD2",
        barSecondaryColor: "#028EDF",

        // color: "red",
        color: "#4fb7f7",
      };
    }
  }

  function checkYearWithGpt(option) {
    const year = option?.expiryYear;
    const currentYear = new Date().getFullYear();
    const minYearDiff = 5;
    const minYearForOutdatedSkill = 4;
    const minYearForCurrentSkill = 1;

    if (!year) {
      return {
        label: "Default",
        barPrimaryColor: "#e0e0e0",
        barSecondaryColor: "#e0e0e0",
      };
    }

    if (year > currentYear) {
      if (option?.chat_gpt === false && option?.estYear === 2010) {
        return {
          label: `Obsolete`,
          barPrimaryColor: "red",
          // barSecondaryColor: "#FFCDD2",
          barSecondaryColor: GetBackGroundColor("Obsolete", option?.type),
          // color: "red",
          color: "#4fb7f7",
        };
      } else {
        return {
          label: `Trending`,
          barPrimaryColor: "green",
          // barSecondaryColor: "#A5D6A7",
          barSecondaryColor: GetBackGroundColor("Trending", option?.type),

          // color: "#26801E",
          color: "#58f374",
        };
      }
    } else if (year >= currentYear - minYearForCurrentSkill) {
      return {
        label: `In demand`,
        barPrimaryColor: "darkorange",
        // barSecondaryColor: "#ffcc80",
        barSecondaryColor: GetBackGroundColor("In demand", option?.type),
        // color: "darkorange",
        color: "#ffd560",
      };
    } else if (year >= currentYear - minYearForOutdatedSkill) {
      return {
        label: `Getting outdated fast`,
        barPrimaryColor: "#ffc107",
        // barSecondaryColor: "#fff59d",
        barSecondaryColor: GetBackGroundColor(`Getting outdated fast`, option?.type),

        // color: "#FF9D09",
        color: "#f74861",
      };
    } else if (year <= currentYear - minYearDiff) {
      return {
        label: `Obsolete `,
        barPrimaryColor: "red",
        // barSecondaryColor: "#FFCDD2",
        barSecondaryColor: GetBackGroundColor(`Obsolete`, option?.type),
        // color: "red",
        color: "#4fb7f7",
      };
    }
  }
  const shineKeyframes = {
    "@keyframes shine": {
      "0%": { backgroundPosition: "-200% 0" },
      "100%": { backgroundPosition: "200% 0" },
    },
  };

  const Legend = () => {
    const currentYear = new Date().getFullYear();
    const years = [currentYear + 1, currentYear, currentYear - 2, currentYear - 6];

    return (
      <Grid container spacing={1} sx={{ mb: 2 }}>
        {/* <Grid item>
          <Chip
            label={`Searching...`}
            // className={year > currentYear ? "shine-effect" : ""}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              color: "rgba(0, 0, 0, 0.87)",
              border: "1px solid rgba(0, 0, 0, 0.36)",
              fontWeight: 600,
            }}
          />
        </Grid> */}
        {years.map((year, index) => {
          const yearInfo = checkYear(year);
          return (
            <Grid item key={index}>
              <Chip
                label={yearInfo.label}
                // className={year > currentYear ? "shine-effect" : ""}
                sx={{
                  backgroundColor: yearInfo?.barSecondaryColor,
                  color: "#fff" || "rgba(0, 0, 0, 0.87)",
                  border: `1px solid ${yearInfo.color}`,
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Typography
        // variant="h6"
        // component="h1"
        // sx={{ fontWeight: "bold", py: 1 }}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="stepper-question"
      >
        <Box>
          {question.label}
          {question.rules.required && (
            <>
              &nbsp;<span style={{ color: "red" }}>*</span>
            </>
          )}
        </Box>

        {question.question_info != null &&
          question.question_info.replace(/\s/g, "")?.length != 0 ? (
          <>
            <TooltipComonent message={question?.question_info} />
          </>
        ) : (
          <></>
        )}
      </Typography>
      {question.questionName === "primarySkill" && autoValue?.length > 0 && <Legend />}
      <Controller
        name={question.questionName}
        control={control}
        rules={question.rules}
        render={({ field }) => (
          <Autocomplete
            noOptionsText="Start typing to get options"
            {...field}
            open={open}
            onOpen={() => {
              setData([]);
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            loading={loading}
            multiple
            autoComplete={true}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            value={autoValue}
            onChange={(event, newValue) => {
              if (newValue.slice(-1)[0] && newValue.slice(-1)[0].inputValue != undefined) {
                setAutoValue([
                  ...autoValue,
                  {
                    name: newValue.slice(-1)[0].inputValue,
                    other: true,
                  },
                ]);
              } else {
                setAutoValue(newValue);
              }
              setData([]);
            }}
            filterSelectedOptions={true}
            onInputChange={(event, value) => {
              setLoading(true);
              const trimmedValue = value?.trim();
              if (trimmedValue) {
                const encodedSkillName = Array.from(trimmedValue)
                  .map((char) => {
                    const isSpecialSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(char);
                    return isSpecialSymbol ? encodeURIComponent(char) : char;
                  })
                  .join("");
                const endpoint = `/get-relevanceSkill?skills=${encodedSkillName}`;
                // const endpoint = `/get-skills?skills=${encodedSkillName}`;
                // getOptions(setData, endpoint, setLoading);

                getSkillOptions(
                  setData,
                  endpoint,
                  setLoading,
                  DataDecryption,
                  getValues,
                  question.questionName
                );
              } else {
                setLoading(false);
              }
            }}
            id="free-solo-dialog-demo"
            options={data}
            getOptionLabel={(option) => {
              // e.g value selected with enter, right from the input
              if (typeof option === "string") {
                return option + "1";
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.name;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            filterOptions={(options, params) => {
              if (!loading) {
                // Trim input value
                let questionName = question.questionName;
                params.inputValue = params.inputValue.trim().toLowerCase();

                // Filter options
                const filtered = filter(options, params);

                // Check if input value matches any existing option
                const isExisting = options.some(
                  (option) => params.inputValue === option.name.toLowerCase()
                );

                // Check if input value matches any selected value
                const selectedValue = getValues(questionName);
                const filteredSelectedValue = selectedValue.find(
                  (item) => item.name.toLowerCase() === params.inputValue
                );
                let filterData = [];

                if (questionName === "primarySkill") {
                  const secondSelectedSkills = getValues("secondarySkill");
                  filterData = new Set(
                    secondSelectedSkills.map((skill) => skill.name.toLowerCase())
                  );
                } else if (questionName === "secondarySkill") {
                  const primarySelectedSkills = getValues("primarySkill");
                  filterData = new Set(
                    primarySelectedSkills.map((skill) => skill.name.toLowerCase())
                  );
                }
                // If input value is not empty and not existing as an option or selected value, add it to filtered options
                if (params.inputValue !== "" && !isExisting && !filteredSelectedValue) {
                  if (!filterData.has(params.inputValue.toLowerCase())) {
                    // setError(questionName, {
                    //   type: "validate",
                    //   message: "Password is required",
                    // });
                    filtered.push({
                      inputValue: params.inputValue,
                      name: `Add "${params.inputValue}" Skill`,
                    });
                  }
                }

                return filtered;
              }
              return options;
            }}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.name, inputValue.trim());
              const parts = parse(option.name, matches);

              return (
                <li {...props} key={option._id}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          color: part.highlight ? "red" : "inherit",
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus={question?.autoFocus}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <Box sx={{ mr: 2 }}>
                          <CircularProgress color="inherit" size={20} />
                        </Box>
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                label={question.placeholder}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const yearInfo = checkYearWithGpt(option);
                // console.log('Option name:', option.name);
                // console.log(yearInfo, "option?.type");
                const iconUrl = getIconBySkillName(option.name);
                // console.log('Icon URL for', option.name, ':', iconUrl);
                return (
                  <Chip
                    {...getTagProps({ index })}
                    key={option?._id}
                    label={option?.name}
                    sx={{
                      backgroundColor: yearInfo?.barSecondaryColor || "rgba(0, 0, 0, 0.08)",
                      color: yearInfo?.label === "Default" ? "#000000" : "#ffffff", // Black text for default, white text otherwise
                      border: `1px solid ${yearInfo?.color}` || "rgba(0, 0, 0, 0.87)",
                    }}
                    avatar={
                      iconUrl ? (
                        <Avatar src={iconUrl} alt="Icon" sx={{ width: 20, height: 20, backgroundColor: '#ffffff', p: "5px", }} />
                      ) : (
                        <Avatar sx={{ fontSize: "10px !important", width: 20, height: 20, backgroundColor: '#ffffff', }}>
                          {(() => {
                            if (!option.name) return '';

                            const words = option.name.split(' ').filter(word => word.length > 0);
                            let abbreviation = '';

                            const getFirstValidChar = (word) => {
                              for (let i = 0; i < word.length; i++) {
                                const char = word.charAt(i).toUpperCase();
                                if (/^[a-zA-Z0-9]$/.test(char)) {
                                  return char;
                                }
                              }
                              return '';
                            };

                            if (words.length === 1) {
                              // Single word: Get the first valid character of the word
                              abbreviation = getFirstValidChar(words[0]);
                            } else if (words.length === 2) {
                              // Two words: Combine the first valid character of each word
                              abbreviation = words.map(word => getFirstValidChar(word)).join('');
                            } else if (words.length === 3) {
                              // Three words: Combine the first valid character of each word
                              abbreviation = words.map(word => getFirstValidChar(word)).join('');
                            } else if (words.length > 3) {
                              // More than three words: Only use the first valid character of the first word
                              abbreviation = getFirstValidChar(words[0]);
                            }

                            // Handle cases where abbreviation might still be empty (due to no valid characters)
                            if (!abbreviation) {
                              abbreviation = words.map(word => getFirstValidChar(word)).join('');
                            }

                            return abbreviation;
                          })()}
                        </Avatar>
                      )
                    }
                  />
                );
              })
            }
            disabled={skillSearchDisable}
          />
        )}
      />
      {errors[question.questionName]?.type === "required" && (
        // <p className={"errorMsg"}>{question.errorMsg}</p>
        <FormHelperText error className={"errorMsg"}>
          {question.errorMsg}
        </FormHelperText>
      )}
      {/* {errors[question.questionName]?.type === "validate" && (
        // <p className={"errorMsg"}>{question.errorMsg}</p>
        <FormHelperText error className={"errorMsg"}>
          {`you entered same skill in ${question.questionName} `}
        </FormHelperText>
      )} */}
      {duplicateError && (
        <Box sx={{ py: 0.5, mt: 1 }}>
          <Alert severity="warning">{duplicateMessage}</Alert>
        </Box>
      )}
      <Grid>
        {autoValue?.length !== 0 && question.questionName === "primarySkill" && (
          <>
            {relevanceLoader ? (
              <Box sx={{ display: "flex", justifyContent: "center", alignItemsL: "center", my: 6 }}>
                <CircularProgress color="primary" size={40} />
              </Box>
            ) : (
              <>
                {relevanceResult[0]?.estYear == null ||
                  relevanceResult[0]?.estYear === 0 ||
                  relevanceResult[0]?.expiryYear == null ||
                  relevanceResult[0]?.expiryYear === 0 ||
                  (relevanceResult[0]?.chat_gpt === false &&
                    relevanceResult[0]?.estYear === 2010 &&
                    relevanceResult[0]?.type === undefined) ? (
                  <Box
                    sx={{
                      border: "0.5px solid #26801E",
                      borderRadius: "5px",
                      background: "rgb(255, 242, 204)",
                      p: 3,
                      my: 2,
                    }}
                  >
                    <Typography
                      color="primary"
                      sx={{
                        textAlign: "center",
                        fontWeight: 600,
                        mb: 1,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                      variant="h6"
                    >
                      Sorry🙄, we could not find the validity of your top skill
                      <span style={{ fontWeight: 700 }}> "{relevanceResult[0]?.name}"</span>
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "262655",
                        // mb:1,
                        fontSize: { xs: "12px", sm: "18px" },
                        fontWeight: 500,
                      }}
                    >
                      Probably, your skill is not Tech/Management-related...
                    </Typography>
                  </Box>
                ) : (
                  <PrimarySkillRelevanceAI relevanceResult={relevanceResult} />
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default SkillMultiSelectQuestionComponent;
