/** @format */

import axios from "axios";
import { setLoading } from "../redux/action/loadingRequest";
import { toast } from "react-hot-toast";
import { getEmail } from "./storage";
import { useSupportEmails } from "../components/common/GlobalFunction";
import ErrorReport from "../components/common/ErrorReport";

let axiosInstance = axios.create({
  timeout: 200000,
});

const supportEmails = ["maulikd@jspinfotech.com"];

const sendErrorReport = async (errorDetails) => {
  // const supportEmail = useSupportEmails();
  try {
    await axios.post(
      "https://api.sendinblue.com/v3/smtp/email",

      {
        sender: {
          name: "API Error Alert",

          email: "support@vucaware.com",
        },

        to: supportEmails.map((email) => ({ email })),
        // to: [{email:'maulikd@jspinfotech.com'}],

        subject: "API Failure Alert (True Selfy)",

        htmlContent: `
          <div style="font-family: Arial, sans-serif; color: #333; line-height: 1.6;">
            <h2 style="color: #d9534f;">API Failure Alert</h2>
            <p>Dear Team,</p>
            <p>An error has occurred in one of the API requests. Please find the details below:</p>
            <table style="width: 100%; border-collapse: collapse;">
              <tr>
                <td style="padding: 8px; border: 1px solid #ddd;"><strong>Error Message:</strong></td>
                <td style="padding: 8px; border: 1px solid #ddd;">${errorDetails.errorMessage}</td>
              </tr>
              <tr>
                <td style="padding: 8px; border: 1px solid #ddd;"><strong>API Endpoint:</strong></td>
                <td style="padding: 8px; border: 1px solid #ddd;">${errorDetails.apiEndpoint}</td>
              </tr>
              <tr>
                <td style="padding: 8px; border: 1px solid #ddd;"><strong>Status Code:</strong></td>
                <td style="padding: 8px; border: 1px solid #ddd;">${errorDetails.statusCode}</td>
              </tr>
              <tr>
                <td style="padding: 8px; border: 1px solid #ddd;"><strong>User Email:</strong></td>
                <td style="padding: 8px; border: 1px solid #ddd;">${getEmail()}</td>
              </tr>
              <tr>
                <td style="padding: 8px; border: 1px solid #ddd;"><strong>App ENV:</strong></td>
                <td style="padding: 8px; border: 1px solid #ddd;">${process.env.REACT_APP_ENV}</td>
              </tr>
            </table>
            <p>Please take the necessary actions to resolve this issue.</p>
            <p>Thank you,</p>
            <p><em>API Monitoring System</em></p>
          </div>
        `,
      },

      {
        headers: {
          "api-key": `${process.env.REACT_APP_SENDIN_BLUE_API_KEY}`,

          "Content-Type": "application/json",
        },
      }
    );

    toast.success("Error report sent successfully");
  } catch (error) {
    console.error("Failed to send error report:", error);

    toast.error("Failed to send error report");
  }
};

const handleApiError = async (err, endpoint) => {
  const errorDetails = {
    errorMessage: err.message,

    apiEndpoint: endpoint,

    statusCode: err.response ? err.response.status : "Network Error",
  };

  if (err.response && err.response.status === 401) {
    window.location.replace("/signin");

    localStorage.clear();

    sessionStorage.setItem("isLoggedIn", false);

    toast.error("Your session has expired. Please log in again.");
  } else {
    // if (process.env.REACT_APP_ENV === "prod") {
    //   await sendErrorReport(errorDetails);
    //   console.log(errorDetails, "errorDetails");
    // }
    // await ErrorReport(errorDetails)
    // toast.error("Internal Server Error");
  }

  return err.response;
};

export const setupAuthInterceptor = (getAuthState) => {
  axiosInstance.interceptors.request.use(async (req) => {
    if (req.url.indexOf("token") === -1 && req.url.indexOf("s3") === -1) {
      const authState = await getAuthState();
      // console.log("authState", authState);
      req.headers.access_token = authState.access_token;
      req.headers.Authorization = authState.id_token;
    }
    // Important: request interceptors **must** return the request.
    return req;
  });

  axiosInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
      await handleApiError(err, err.config.url);
      throw err;
    }
  );
};

const OK_STATUSES = [200, 204];

const CONTENT_TYPES = {
  json: "application/json",
  form: "application/x-www-form-urlencoded",
};

export const apiTimeout = { timeout: 80000 };

export const defaultApiError = "Looks like there's a problem on our end, please try again";

export const isResponseOK = (response) => response && OK_STATUSES.includes(response.status);

export const getUserError = ({ response, apiMessages, defaultError = defaultApiError }) =>
  response && response.data ? apiMessages[response.data.message] || defaultError : defaultError;

const getAdditionalHeaders = (headers = {}) => ({
  ...headers,
});

export const getContentType = (type) => type || "json";

export const GET = async ({ endpoint, options = {}, cancelToken }) => {
  try {
    return await axiosInstance.get(process.env.REACT_APP_API_BASE_URL + endpoint, {
      headers: {
        ...getAdditionalHeaders(options.headers),
      },
      ...apiTimeout,
      cancelToken: cancelToken,
    });
  } catch (err) {
    return await handleApiError(err, endpoint);
  }
};

export const POST = async ({
  endpoint,
  payload = {},
  dispatch,
  options = {},
  navigate,
  setUserResult,
}) => {
  try {
    let uri = endpoint;

    if (!options.fullUrl) {
      uri = process.env.REACT_APP_API_BASE_URL + endpoint;
    }

    return await axiosInstance.post(uri, payload, {
      headers: {
        "Content-Type": CONTENT_TYPES[getContentType(options.type)],
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
        ...getAdditionalHeaders(options.headers),
      },
      ...apiTimeout,
    });
  } catch (err) {
    dispatch(setLoading(false));
    // dispatch(setUserResult(""));

    return await handleApiError(err, endpoint);
  }
};

export const DELETE = async ({ endpoint, options = {} }) => {
  try {
    return await axiosInstance.delete(process.env.REACT_APP_API_BASE_URL + endpoint, {
      headers: {
        ...getAdditionalHeaders(options.headers),
      },
      ...apiTimeout,
    });
  } catch (err) {
    return await handleApiError(err, endpoint);
  }
};

export const PATCH = async ({ endpoint, payload = {}, options = {} }) => {
  try {
    return await axiosInstance.patch(process.env.REACT_APP_API_BASE_URL + endpoint, payload, {
      headers: {
        "Content-Type": "application/json-patch+json",
        ...getAdditionalHeaders(options.headers),
      },
      ...apiTimeout,
    });
  } catch (err) {
    return await handleApiError(err, endpoint);
  }
};

export const PUT = async ({ endpoint, payload = {}, options = {} }) => {
  try {
    return await axiosInstance.put(process.env.REACT_APP_API_BASE_URL + endpoint, payload, {
      headers: {
        "Content-Type": "application/json",
        ...getAdditionalHeaders(options.headers),
      },
      ...apiTimeout,
    });
  } catch (err) {
    return await handleApiError(err, endpoint);
  }
};

export const S3_DOC_PUT = async ({ endpoint, payload = {} }) => {
  try {
    return await axiosInstance.put(endpoint, payload, {
      headers: {
        "Content-Type": "application/pdf",
      },
    });
  } catch (err) {
    return await handleApiError(err, endpoint);
  }
};

export default {
  GET,
  POST,
  DELETE,
  PUT,
  S3_DOC_PUT,
  PATCH,
  isResponseOK,
  getUserError,
};
