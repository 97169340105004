/** @format */ import React, { useContext, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import "../styles.css";
import { Amplify } from "aws-amplify";
import { Auth } from "aws-amplify";
import FooterComponent from "../components/Footer";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import TooltipComponent from "../components/common/Tooltip";
import "./Login.css";
import Checkbox from "@mui/material/Checkbox";
import { setupAuthInterceptor } from "../utils/apis";
import { OAuthCtx } from "../auth/AuthContext";
import {
  Alert,
  Button,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  FormLabel,
} from "@mui/material";
import { userLogin, googleUserLogin } from "../components/stepper_form/stepper.helper";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { setUserStatus } from "../redux/action/user";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import DataDecryption from "../components/common/DataDecryption";
import { useMediaQuery, makeStyles } from "@mui/material";
import {
  getCountryCode,
  getRedirectSignInURL,
  getRedirectSignOutURL,
} from "../components/common/GlobalFunction";
import { getEmail, saveToSessionStorage, saveTargetPath } from "../utils/storage";
import Loading from "../components/UI/Loading";
import { Helmet } from "react-helmet";
import { getPreviousResult } from "../components/stepper_form/stepper.helper";
// Import the configuration
import "../utils/amplifyConfig";
import ResponsiveDialog from "../components/common/ResponsiveDialog";
import { LinkedIn } from "@mui/icons-material";
import {
  MAINTENANCE_BREAK,
  SHOW_GOOGLE_LOGIN_BUTTON,
  SHOW_LINKEDIN_LOGIN_BUTTON,
} from "../components/common/GlobalVariable";
import { selectedGridRowsCountSelector } from "@mui/x-data-grid";
import ReactGA4 from "react-ga4";
import DeviceInfoAndLocation from "../components/common/DeviceInfoAndLocation";
import { truncate } from "lodash";
import LoadingPage from "../components/UI/Loading";
import { setIncognitoMode } from "../redux/action/IncognitoMode";
import { Icon } from "@iconify-icon/react";

export default function Login() {
  const { setAuthState } = useContext(OAuthCtx);
  const { getAuthState } = useContext(OAuthCtx);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const staticRandomEncodeDecodeString = useFeatureFlag("staticRandomEncodeDecodeString");
  const secretEncodeDecodeString = useFeatureFlag("secretEncodeDecodeString");
  const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true" ? true : false;
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const uniqueReferredUserId = params.get("ref");
  const MaintenanceMessage = useFeatureFlag(MAINTENANCE_BREAK);
  const showLinkedInButton = useFeatureFlag(SHOW_LINKEDIN_LOGIN_BUTTON);
  const showGoogleButton = useFeatureFlag(SHOW_GOOGLE_LOGIN_BUTTON);
  const userType = params.get("link-type") || "copy-link";
  const referBy = params.get("referBy");
  const [location, setLocation] = React.useState();
  const [deviceInfo, setDeviceInfo] = React.useState();

  const [openResponsiveDialog, setResponsiveDialog] = React.useState(false);
  const [LoginButtonShow, setLoginButtonShow] = React.useState(true);

  useEffect(() => {
    const redirectLink = params.get("link");
    if (redirectLink === "/endorse-expertise") {
      saveTargetPath(redirectLink);
      saveToSessionStorage("uniqueReferredUserId", uniqueReferredUserId);
      dispatch(
        setUserStatus({
          isLoggedIn: sessionStorage.getItem("isLoggedIn") === "true" ? true : false,
          userRefId: sessionStorage.getItem("userRefId"),
          uniqueReferredUserId: uniqueReferredUserId,
          type: userType,
        })
      );
    }
    if (isLoggedIn && redirectLink === "/endorse-expertise") {
      saveToSessionStorage("uniqueReferredUserId", uniqueReferredUserId);
      dispatch(
        setUserStatus({
          isLoggedIn: sessionStorage.getItem("isLoggedIn") === "true" ? true : false,
          userRefId: sessionStorage.getItem("userRefId"),
          uniqueReferredUserId: uniqueReferredUserId,
          type: userType,
        })
      );

      navigate(redirectLink);
    } else if (isLoggedIn && referBy) {
      navigate("/");
      localStorage.setItem("loggedInUserReferBy", "true");
      dispatch(
        setUserStatus({
          isLoggedIn: sessionStorage.getItem("isLoggedIn") === "true" ? true : false,
          userRefId: sessionStorage.getItem("userRefId"),
          referBy: referBy,
        })
      );
    }
    if (referBy !== null) {
      localStorage.setItem("referBy", referBy);
      dispatch(
        setUserStatus({
          isLoggedIn: sessionStorage.getItem("isLoggedIn") === "true" ? true : false,
          userRefId: sessionStorage.getItem("userRefId"),
          referBy: referBy,
        })
      );
    } else if (isLoggedIn) {
      navigate("/");
    } else {
      localStorage.clear();
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("id_token");
    }
  }, []);

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  let EnteredEmail = watch("email") || "";
  const isMobile = useMediaQuery("(max-width:600px)");

  const AllowDomains = useFeatureFlag("AllowDomainsOnly");
  const EMAIL_GHOST_TEXT = `${isMobile ? "Enter Email" : "Enter your personal email id"}`;
  // const EMAIL_ERROR_MSG =
  //   "Oh no, that doesn't look like a valid email! Please double-check and try again.";
  // const OTP_GHOST_TEXT_OLD = `Enter the OTP received in email sent to ${getValues(
  //   "email"
  // )}`;
  const OTP_GHOST_TEXT = `Enter the OTP`;
  const OTP_ERROR_MSG = `Oh no, that doesn't look like a valid OTP! Please double-check and enter the OTP sent to ${getValues(
    "email"
  )}.`;

  const [errorHandler, setErrorHandler] = React.useState({
    error: 0,
    errorMsg: "",
  });
  const [cognitoUserstate, setCognitoUserstate] = React.useState();
  const [showOTPForm, setShowOTPForm] = React.useState(false);
  const Img = styled("img")({
    // margin: "auto",
    objectFit: "cover",
    width: "100%",
    height: "auto",
  });
  const [agreementCheck, setAgreementCheck] = React.useState(false);
  const [IncognitoModeStatus, setIncognitoModeStatus] = React.useState(false);

  const [loadingButton, setLoadingButton] = React.useState(false);
  const [domainList, setDomainList] = React.useState([
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "msn.com",
    "comcast.net",
    "yahoo.co.in",
    "live.com",
    "rediffmail.com",
    "ymail.com",
    "outlook.com",
    "googlemail.com",
    "rocketmail.com",
    "facebook.com",
    "yahoo.in",
    "me.com",
    "mail.com",
  ]);

  //  check user entered email or not with term & service
  useEffect(() => {
    if (EnteredEmail.length > 0) {
      if (agreementCheck) {
        setLoginButtonShow(false);
      } else {
        setLoginButtonShow(true);
      }
    } else {
      setLoginButtonShow(true);
    }
    console.log(EnteredEmail, "EnteredEmail");
  }, [EnteredEmail, agreementCheck]);

  //dispatch event for Incognito mode
  useEffect(() => {
    if (IncognitoModeStatus) {
      localStorage.setItem("IncognitoUser", true);
      dispatch(setIncognitoMode(true));
    } else {
      localStorage.setItem("IncognitoUser", false);
      dispatch(setIncognitoMode(false));
    }
  }, [IncognitoModeStatus]);

  function getRandomString(bytes) {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join("");
  }
  function intToHex(nr) {
    return nr.toString(16).padStart(2, "0");
  }
  //Login with register
  const handleSubmitCustom = async (data) => {
    if (MaintenanceMessage != "undefined") {
      setResponsiveDialog(true);
      return;
    }

    let email = data.email.toLowerCase();
    setLoadingButton(true);
    var idx1 = email.indexOf("@");
    if (AllowDomains) {
      if (idx1 > -1) {
        var splitStr = email.split("@");
        // var sub = splitStr[1].split(".");

        var splitStr1 = splitStr[1].split(".");
        var jointSplitStr = "." + splitStr1[1];

        if (domainList?.indexOf(splitStr[1]) < 0) {
          if (domainList?.indexOf(jointSplitStr) < 0) {
            setError("email", {
              type: "validomain",
              message: `Enter email with given domains`,
            });
            setLoadingButton(false);
            return;
          }
        }
      }
    }

    const params = {
      username: email,
      password: getRandomString(30),
      attributes: {
        email: email,
      },
    };
    var respons = [];

    try {
      //Check and user add user
      respons = await Auth.signUp(params);
    } catch (error) {
      if (error.message == "User already exists") {
        try {
          const cognitoUser = await Auth.signIn(email);
          setCognitoUserstate(cognitoUser);
          setShowOTPForm(true);
          const cstmError = { error: 0, errorMsg: "" };
          setLoadingButton(false);
          setErrorHandler(cstmError);
          localStorage.setItem("userEmail", cognitoUser.username);
        } catch (error) {
          const cstmError = { error: 1, errorMsg: error.message };
          setErrorHandler(cstmError);
          setLoadingButton(false);
        }
      } else {
        const cstmError = { error: 1, errorMsg: error.message };
        setErrorHandler(cstmError);
        setLoadingButton(false);
      }
    }
    if (respons.userConfirmed == true) {
      try {
        const cognitoUser = await Auth.signIn(email);
        setCognitoUserstate(cognitoUser);
        setShowOTPForm(true);

        const cstmError = { error: 0, errorMsg: "" };
        setErrorHandler(cstmError);
        localStorage.setItem("userEmail", cognitoUser.username);
        setLoadingButton(false);
      } catch (error) {
        const cstmError = { error: 1, errorMsg: error.message };
        setErrorHandler(cstmError);
        setLoadingButton(false);
      }
    }
  };

  const handleOTPSubmit = async (data) => {
    // event.preventDefault();
    //const data = new FormData(event.currentTarget);
    setLoadingButton(true);
    let otp = data.otp;
    let cognitoUser = {};
    try {
      cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUserstate, otp);
      if (cognitoUser.challengeName == "CUSTOM_CHALLENGE") {
        setError("otp", {
          type: "notvalid",
          message: "OTP is not valid please again!",
        });
      }
      try {
        var currentSession = null;
        currentSession = await Auth.currentSession();
        await setAuthState(currentSession);
        setupAuthInterceptor(getAuthState);
        if (referBy !== null) {
          const payload = {
            email: cognitoUser.username,
            registerTime: new Date(Date.now()).toUTCString(),
            lastLoginTime: new Date(Date.now()).toUTCString(),
            termsCondition: true,
            uniqueReferredUserId: params.get("ref"),
            type: userType,
            referBy: referBy,
            referTime: new Date(Date.now()).toUTCString(),
          };
          await userLogin(payload, navigate, dispatch, setUserStatus, ReactGA4);
        } else {
          const payload = {
            email: cognitoUser.username,
            registerTime: new Date(Date.now()).toUTCString(),
            lastLoginTime: new Date(Date.now()).toUTCString(),
            termsCondition: true,
            uniqueReferredUserId: params.get("ref"),
            type: userType,
          };
          await userLogin(payload, navigate, dispatch, setUserStatus, ReactGA4);
        }
      } catch (e) {
        setError("otp", {
          type: "notvalid",
          message: "OTP is not valid please again!",
        });
        setLoadingButton(false);
      }
    } catch (e) {
      setError("otp", {
        type: "NotAuthorizedException",
        message:
          "The OTP you have entered is invalid, and you have reached the maximum number of attempts allowed to enter it. Please return to the Sign In page and try again.",
      });
      setLoadingButton(false);
    }
  };

  function getAllowDomains() {
    try {
      axios
        .get(process.env.REACT_APP_API_BASE_URL + "/get-emailBlackListDomain", {
          options: {
            type: "json",
          },
        })
        .then((object) => {
          let EmailDomainList = DataDecryption(
            object.data,
            staticRandomEncodeDecodeString,
            secretEncodeDecodeString
          );
          setDomainList(EmailDomainList.emailDomainList[0].domains);
        });
    } catch (error) {
      console.log("error", error);
    }
  }
  const [loading, SetLoading] = useState(true);
  const [isAllowed, setIsAllowed] = React.useState(true);
  const Allowed_Countries = useFeatureFlag("Allowed_Countries");
  const Allowed_Countries_JSON = JSON.parse(Allowed_Countries);
  // Parse the JSON string into an array
  let allowedCountriesArray = [];
  try {
    allowedCountriesArray = JSON.parse(Allowed_Countries_JSON);
  } catch (error) {
    console.error("Failed to parse Allowed_Countries_JSON:", error);
  }

  // Format allowed countries into a readable sentence
  const allowedCountries = allowedCountriesArray.join(", ").replace(/,([^,]*)$/, " and$1");

  useEffect(() => {
    getAllowDomains();
    // if (process.env.REACT_APP_ENV === "dev") {
    //   let domainListDev = setDomainList([...domainList, "jspinfotech.com"]);
    //   setDomainList(domainListDev);
    // }
    ReactGA4.event({
      category: "User-Login-Event",
      action: "called",
      label: "pre-login", // optional
    });

    // check user are from allowed countries or not
    const fetchCountry = async () => {
      const CountryCode = await getCountryCode(SetLoading);
      const allowedUser = !Allowed_Countries_JSON.includes(CountryCode[0]["country_name"]);
      setIsAllowed(allowedUser);
    };
    fetchCountry();
  }, []);

  const domains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "msn.com",
    "comcast.net",
    "yahoo.co.in",
    "live.com",
    "rediffmail.com",
    "ymail.com",
    "outlook.com",
    "googlemail.com",
    "rocketmail.com",
    "facebook.com",
    "yahoo.in",
    "me.com",
    "mail.com",
    "trueselfy.com",
  ];
  const showDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "msn.com",
    "comcast.net",
    "yahoo.co.in",
    "live.com",
    "rediffmail.com",
    "ymail.com",
    "outlook.com",
    "googlemail.com",
    "rocketmail.com",
    "facebook.com",
    "yahoo.in",
    "me.com",
    "mail.com",
  ];

  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCheckboxChange = (event) => {
    setIsChecked(!isChecked);
    setAgreementCheck(!agreementCheck);
    // Clear error message when user checks the checkbox
    if (event.target.checked) {
      setErrorMessage("");
    }
  };
  const handleIncognitoChange = (event) => {
    setIncognitoModeStatus(!IncognitoModeStatus);
  };

  return (
    <>
      {openResponsiveDialog && (
        <ResponsiveDialog
          openResponsiveDialog={openResponsiveDialog}
          setResponsiveDialog={setResponsiveDialog}
          MaintenanceMessage={MaintenanceMessage}
        />
      )}
      <Helmet>
        <title>Future Pay Scale Prediction Calculator Tool Online | Endorse My Expertise</title>
        <meta
          name="description"
          content="Boost my professional standing and assist in determining my rightful pay scale! Only 2 minutes of your time can make a significant impact. Your endorsement means a lot!"
        />
        <meta
          property="og:title"
          content="Future Pay Scale Prediction Calculator Tool Online | Endorse My Expertise"
        />
        <meta property="og:type" content="product" />
        <meta
          property="og:description"
          content="Boost my professional standing and assist in determining my rightful pay scale! Only 2 minutes of your time can make a significant impact. Your endorsement means a lot!"
        />
        <meta property="og:url" content="https://master.d3hqz1cwjn0bmv.amplifyapp.com/signin" />
      </Helmet>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
          position="fixed"
        >
          {/* <CircularProgress /> */}
          {isMobile ? (
            <img src={"../../../assets/images/loading-mobile.gif"} alt="loading..." />
          ) : (
            <img src={"../../../assets/images/loader3.gif"} alt="loading..." />
          )}
        </Box>
      ) : (
        <>
          {isAllowed ? (
            <Grid
              container
              spacing={0}
              sx={{
                // minHeight: {
                //   xs: "calc(100vh - 160px)",
                //   md: "calc(100vh)",
                //   lg: "calc(100vh - 232px)",
                // },

                height: "100%",
                // backgroundImage: {
                //   xs: "none",
                //   md: showOTPForm
                //     ? `url('/assets/images/otp-bg-pizza.png')`
                //     : `url('/assets/images/signin-backimg.png')`,
                // },
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: showOTPForm ? "top" : "center",
              }}
            >
              <Grid
                item
                display={"flex"}
                flexDirection="column"
                justifyContent={"flex-start"}
                // alignItems="center"
                lg={6}
                md={12}
                xs={12}
                sx={{
                  height: "100vh",

                  // mt: { xs: 18, md: 12 },
                }}
              >
                <Box
                  sx={{
                    pl: { xs: 0, lg: "96px" },
                    pt: 3,
                    width: "100%",
                    textAlign: { xs: "center", md: "center", lg: "left" },

                    // position: "absolute",
                    // top: 8,
                    // right: { xs: "40vw", md: "45vw", lg: "89vw" },
                  }}
                >
                  <a href="/">
                    <img
                      src={"../../assets/images/TrueSelfy-logo.svg"}
                      alt="true-selfy"
                      style={{
                        maxWidth: `${isMobile ? "80px" : ""}`,
                      }}
                    />
                  </a>
                </Box>

                {showOTPForm == false ? (
                  <Box
                    sx={{
                      // border: 1,
                      // borderRadius: "25px",
                      // borderColor: "#FFC218",
                      display: "flex",
                      // alignItems: "center",
                      flexDirection: "column",
                      justifyContent: { xs: "center", md: "center", lg: "center" },
                      maxWidth: { xs: 281, sm: 499 },
                      width: "100%",
                      height: "100%",
                      background: "#FFF",
                      margin: "0 auto",
                      p: { xs: "16px", md: "30px" },
                    }}
                    component="form"
                    onSubmit={handleSubmit(handleSubmitCustom)}
                  >
                    <Typography
                      sx={{ fontSize: "32px", fontWeight: 500, mb: { xs: "5px", lg: "41px" } }}
                      textAlign={"center"}
                    >
                      Sign In
                    </Typography>
                    <FormLabel sx={{ pb: 0.5, pl: 0.2, color: "#323238" }}>Email</FormLabel>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Email Address is required",
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message:
                            "Oh no, that doesn't look like a valid email! Please double-check and try again.",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          error={errors?.email ? true : false}
                          {...field}
                          fullWidth
                          id="email"
                          type="text"
                          sx={{ mb: "23px" }}
                          // size="small"
                          InputProps={{
                            sx: {
                              paddingRight: "16px",
                            },
                            endAdornment: (
                              <InputAdornment position="start">
                                <TooltipComponent size="small" message={domainList} />
                              </InputAdornment>
                            ),
                          }}
                          label={EMAIL_GHOST_TEXT}
                          helperText={
                            <>
                              {errors?.email && errors?.email.message}
                              {errors?.email && errors.type === "validomain"?.email?.message}
                            </>
                          }
                        />
                      )}
                    />
                    <Box
                      alignItems={"start"}
                      display="flex"
                      sx={{
                        cursor: "pointer",
                      }}
                      // onClick={() => setAgreementCheck(!agreementCheck)}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography>
                            I agree to the
                            <Link
                              href="https://vucaware.com/terms-and-conditions/"
                              target="_blank"
                              sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                            >
                              Terms of Service
                            </Link>
                            &
                            <Link
                              href="https://vucaware.com/privacy-policy/"
                              target="_blank"
                              sx={{ marginLeft: 0.5 }}
                            >
                              Privacy Policy
                            </Link>
                          </Typography>
                        }
                      />
                    </Box>
                    {errorMessage && (
                      <Typography variant="body2" color="error">
                        {errorMessage}
                      </Typography>
                    )}
                    <Box
                      alignItems={"center"}
                      display="flex"
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={IncognitoModeStatus}
                            onChange={handleIncognitoChange}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ pr: 0.5, py: 0.5 }}>
                              <iconify-icon
                                alignItems="center"
                                height="1.5em"
                                icon="mdi:incognito"
                              ></iconify-icon>
                            </Box>
                            Incognito Mode (What-IF analysis)
                            {/* <Link
                              href="https://vucaware.com/terms-and-conditions/"
                              target="_blank"
                              sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                            >
                              Terms of Service
                            </Link>
                            &
                            <Link
                              href="https://vucaware.com/privacy-policy/"
                              target="_blank"
                              sx={{ marginLeft: 0.5 }}
                            >
                              Privacy Policy
                              </Link> */}
                          </Typography>
                        }
                      />
                      <Box>
                        <TooltipComponent
                          size="small"
                          message={`What is Incognito Mode?
                            Incognito Mode is a feature that enhances your privacy by deleting your form data immediately after use, 
                            ensuring your personal information is protected. It also makes your data untraceable, 
                            You pay full price in Incognito Mode, You also cannot provide references for results/what if analysis done in this mode.
                            ("Incognito Mode is just the session.")`}
                        ></TooltipComponent>
                      </Box>
                    </Box>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      sx={{
                        mt: 3,
                        mb: 2,
                        backgroundColor: "#2BB344",
                        fontSize: { xs: "15px", md: "20px" },
                      }}
                      disabled={LoginButtonShow}
                      loading={loadingButton}
                    >
                      Get OTP
                    </LoadingButton>
                    {/* {process.env.REACT_APP_ENV != "prod" && ( */}
                    {/* <Button
                    variant="text"
                    fullWidth
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "#FFF",
                      color: "#232323",
                      fontSize: { xs: "15px", md: "20px" },
                      fontWeight: 500,
                      border: "1px solid #26801E",
                      mb: "16px",
                      "&:hover,&:focus": {
                        border: "1px solid #26801E",
                      },
                    }}
                    onClick={async () => {
                      try {
                        // Perform Google federated sign-in
                        if (MaintenanceMessage != "undefined") {
                          setResponsiveDialog(true);
                          return;
                        }
                        await Auth.federatedSignIn({
                          provider: "Google",
                        });

                        console.log("User attributes updated successfully");
                      } catch (error) {
                        console.error(
                          "Error during Google federated sign-in or attribute update",
                          error
                        );
                      }
                    }}
                  >
                    <img
                      src="../../../assets/images/google-logo.png"
                      width="26px"
                      height="auto"
                      alt="google-logo"
                    />{" "}
                    Sign in with Google
                  </Button>
                  <Button
                    variant="text"
                    fullWidth
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "#FFF",
                      color: "#232323",
                      fontSize: { xs: "15px", md: "20px" },
                      fontWeight: 500,
                      border: "1px solid #007BB5",

                      "&:hover,&:focus": {
                        border: "1px solid #007BB5",
                        background: "rgba(0,123,181,0.04)",
                      },
                    }}
                  >
                    <img
                      src="../../../assets/images/linkedin-rounded.png"
                      width="26px"
                      height="auto"
                      alt="google-logo"
                    />{" "}
                    Sign in with Linkedin
                  </Button> */}

                    {/* <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    {showGoogleButton && (
                      <IconButton
                        sx={{
                          width: { xs: "40px", md: "50px" },
                          height: { xs: "40px", md: "50px" },
                          p: "0px",
                          "& >img": { width: "100%", height: "100%" },
                        }}
                        onClick={async () => {
                          try {
                            // Perform Google federated sign-in
                            if (MaintenanceMessage != "undefined") {
                              setResponsiveDialog(true);
                              return;
                            }
                            await Auth.federatedSignIn({
                              provider: "Google",
                            });

                            console.log("User attributes updated successfully");
                          } catch (error) {
                            console.error(
                              "Error during Google federated sign-in or attribute update",
                              error
                            );
                          }
                        }}
                      >
                        <img src="/assets/images/google-short-logo.png" alt="google-logo" />
                      </IconButton>
                    )}
                    {showLinkedInButton && (
                      <IconButton
                        sx={{
                          width: { xs: "40px", md: "50px" },
                          height: { xs: "40px", md: "50px" },
                          p: "0px",
                          "& >img": { width: "100%", height: "100%" },
                        }}
                        onClick={async () => {
                          try {
                            // Perform Google federated sign-in
                            if (MaintenanceMessage != "undefined") {
                              setResponsiveDialog(true);
                              return;
                            }
                            await Auth.federatedSignIn({
                              provider: "LinkedIn",
                            });

                            console.log("User attributes updated successfully");
                          } catch (error) {
                            console.error(
                              "Error during Google federated sign-in or attribute update",
                              error
                            );
                          }
                        }}
                      >
                        <img src="/assets/images/linkedin-short-logo.png" alt="linkedin-logo" />
                      </IconButton>
                    )}
                  </Box> */}

                    {/* )} */}
                    {(showGoogleButton || showLinkedInButton) && (
                      <Divider>
                        <span style={{ color: "#e0e0e0" }}>OR</span>
                      </Divider>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        justifyContent: "center",
                        // alignItems: "center",
                        mt: 2,
                      }}
                    >
                      {showGoogleButton && (
                        <Button
                          variant="text"
                          fullWidth
                          sx={{
                            display: "flex",
                            gap: 1,
                            px: 3,
                            alignItems: "center",
                            justifyContent: "center",
                            bgcolor: "#FFF",
                            color: "rgb(35, 35, 35)",
                            fontSize: { xs: "15px", md: "24px" },
                            fontWeight: 500,
                            p: { xs: "6px 16px", md: "8px 22px", lg: "8px 24px" },
                            border: "1.5px solid #878787",
                            // mb: "16px",
                            "&:hover,&:focus": {
                              border: "1px solid #26801E",
                            },
                          }}
                          onClick={async () => {
                            try {
                              // Perform Google federated sign-in
                              if (MaintenanceMessage != "undefined") {
                                setResponsiveDialog(true);
                                return;
                              }
                              if (!isChecked) {
                                setErrorMessage(
                                  "Please agree to the Terms of Service & Privacy Policy"
                                );
                                return;
                              }
                              await Auth.federatedSignIn({
                                provider: "Google",
                              });

                              // console.log("User attributes updated successfully");
                            } catch (error) {
                              console.error(
                                "Error during Google federated sign-in or attribute update",
                                error
                              );
                            }
                          }}
                        >
                          <img
                            src="../../../assets/images/google-logo.png"
                            style={{
                              maxWidth: "26px",
                            }}
                            height="auto"
                            alt="google-logo"
                          />{" "}
                          Google
                        </Button>
                      )}
                      {showLinkedInButton && (
                        <Button
                          variant="text"
                          fullWidth
                          sx={{
                            display: "flex",
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                            bgcolor: "#FFF",
                            color: "rgb(35, 35, 35)",
                            fontSize: { xs: "15px", md: "24px" },
                            fontWeight: 500,
                            p: { xs: "6px 16px", md: "8px 22px", lg: "8px 24px" },
                            border: "1.5px solid #878787",
                            "&:hover,&:focus": {
                              border: "1px solid #007BB5",
                              background: "rgba(0,123,181,0.04)",
                            },
                          }}
                          onClick={async () => {
                            try {
                              // Perform Google federated sign-in
                              if (MaintenanceMessage != "undefined") {
                                setResponsiveDialog(true);
                                return;
                              }
                              if (!isChecked) {
                                setErrorMessage(
                                  "Please agree to the Terms of Service & Privacy Policy"
                                );
                                return;
                              }
                              sessionStorage.setItem("clickedSocialMediaLogin", true);
                              await Auth.federatedSignIn({
                                provider: "LinkedIn",
                              });

                              // console.log("User attributes updated successfully");
                            } catch (error) {
                              console.error(
                                "Error during Google federated sign-in or attribute update",
                                error
                              );
                            }
                          }}
                        >
                          <img
                            src="../../../assets/images/linkedin-square.png"
                            width="26px"
                            height="auto"
                            alt="google-logo"
                          />{" "}
                          Linkedin
                        </Button>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      borderRadius: "25px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { xs: "center", md: "center", lg: "center" },

                      flexDirection: "column",
                      maxWidth: { xs: 281, sm: 499 },
                      width: "100%",
                      height: "100%",
                      background: "#FFF",
                      margin: "0 auto",
                      background: "#FFF",
                      p: { xs: "16px", md: "30px" },
                    }}
                    component="form"
                    onSubmit={handleSubmit(handleOTPSubmit)}
                  >
                    <Typography
                      sx={{ fontSize: "32px", fontWeight: 500, mb: "10px" }}
                      textAlign={"center"}
                    >
                      Enter the OTP
                    </Typography>
                    <Alert
                      icon={false}
                      severity="success"
                      sx={{ justifyContent: "center", mb: "37px" }}
                    >
                      {/* Received in the email sent to your email id */}
                      We’ve sent a verification code to your E-mail
                    </Alert>

                    <Controller
                      name="otp"
                      control={control}
                      rules={{
                        required: "OTP is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: OTP_ERROR_MSG,
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={errors?.otp ? true : false}
                          fullWidth
                          id="otp"
                          type="number"
                          label={OTP_GHOST_TEXT}
                          helperText={<>{errors?.otp && errors?.otp.message}</>}
                        />
                      )}
                    />
                    <Box alignItems={"center"} textAlign="center">
                      <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 4, mb: 2 }}
                        loading={loadingButton}
                      >
                        Submit
                      </LoadingButton>
                      <Link href="/signin" color="primary" fontSize="small" textAlign={"center"}>
                        Back to Sign in
                      </Link>
                    </Box>
                  </Box>
                )}
              </Grid>

              <Grid
                item
                xs={0}
                md={0}
                lg={6}
                sx={{
                  display: { xs: "none", md: "node", lg: "flex" },
                  // justifyContent: "center",
                  // alignItems: "center",
                  height: "100vh",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundImage: `url('/assets/images/login-page-img.svg')`,
                  backgroundColor: "rgba(35, 28, 79, 0.4) !important",
                }}
                // flexDirection="row"
              ></Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              sx={{
                height: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: showOTPForm ? "top" : "center",
                p: { xs: 2, md: 4 },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Logo Section */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", lg: "center" },
                    // pl: { xs: 0, lg: "96px" },
                    pt: 3,
                    width: "100%",
                  }}
                >
                  <a href="/">
                    <img
                      src={"../../assets/images/TrueSelfy-logo.svg"}
                      alt="true-selfy"
                      style={{
                        maxWidth: isMobile ? "80px" : "150px",
                      }}
                    />
                  </a>
                </Box>
              </Grid>

              {/* Message Section */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    pt: 3,
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                    Access Restricted
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    Unfortunately, access to this site is currently limited. We apologize for any
                    inconvenience
                  </Typography>
                  {/* <Typography variant="body2" sx={{ mb: 3, fontWeight: 600 }}>
                    {allowedCountries}
                  </Typography> */}
                  <Typography variant="body2">
                    Thank you for visiting. If you have any questions or require assistance, please
                    contact our support team.
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "center", pt: 2 }}>
                  <Link
                    color="inherit"
                    href={`${process.env.REACT_APP_BASE_URL}/contact-us`}
                    sx={{ cursor: "pointer" }}
                  >
                    Contact support
                  </Link>
                </Box>
              </Grid>

              {/* Return Button */}
              <Grid item xs={12} sx={{ textAlign: "center", mt: 1 }}>
                <Button variant="contained" color="primary" href="/" sx={{ mt: 1 }}>
                  Go Back to Home
                </Button>
              </Grid>
            </Grid>
          )}

          {/* <FooterComponent /> */}
        </>
      )}
    </>
  );
}
