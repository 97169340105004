import { Grid } from "@mui/material";
import MultiSelectQuestionComponent from "./question-type-components/MultiSelectQuestionComponent";
import SkillMultiSelectQuestionComponent from "./question-type-components/SkillMultiSelectQuestionComponent";
import NumberQuestionComponent from "./question-type-components/NumberQuestionComponent";
import RadioQuestionComponent from "./question-type-components/RadioQuestionComponent";
import SelectQuestionComponent from "./question-type-components/SelectQuestionComponent";
import TextQuestionComponent from "./question-type-components/TextQuestionComponent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./StepperForm.css";
import { useEffect } from "react";
import DynamicMultiSelectQuestionComponent from "./question-type-components/DynamicMultiSelectQuestionComponent";
import PrimarySkillRelevanceAI from "./question-type-components/PrimarySkillRelevanceAI";
import { callDummyApi, googleUserLogin } from "./stepper.helper";
import { useDispatch, useSelector } from "react-redux";
import { setUserStatus } from "../../redux/action/user";
import { Auth } from "aws-amplify";
import { customGoogleUserLogin } from "../common/GlobalFunction";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";

// import useMediaQuery from "@mui/material/useMediaQuery";

function SingleStepForm({ questionsObject, activeStepObject }) {
  const userEmail = localStorage.userEmail || "";
  const dispatch = useDispatch();
  const Software_Personnel_Title = useFeatureFlag("Software_Personnel_Title");

  // useEffect(() => {
  //   customGoogleUserLogin(dispatch, uniqueReferredUserId);
  // }, []);
  useEffect(() => {
    /* Dummy Use Effect to active skill and certificate lambda */
    // Call the first function
    if (activeStepObject?.order < 4) {
      callDummyApi("/get-skills?skills=java");
      callDummyApi("/get-certificates?certificates=aws");
    }
  }, [activeStepObject.stepId]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [activeStepObject]);
  return (
    <Box>
      {/* <Box display={"flex"} alignItems="center" justifyContent={"center"} sx={{ pb: 4 }}> */}
      {/* <img
          src="../../assets/images/india-flag.png"
          alt="indian-flag"
          style={{ maxWidth: "40px" }}
        /> */}
      {/* <Typography variant="h3" gutterBottom sx={{ mb: 0 }}>
          {Software_Personnel_Title}
        </Typography> */}
      {/* </Box> */}
      <Box
        sx={{
          position: "relative",
          display: { xs: "none", sm: "flex" },
          alignItems: "center",
          justifyContent: { xs: "center", md: "left" },
          // pt: 12,
          // pb: 3,
          mb: "10px",
        }}
      >
        {activeStepObject.stepId === "step_8" ? (
          <></>
        ) : (
          <>
            {/* <Box className="avatar">
              <Avatar
                className="avatar-img"
                src={`../../../../assets/images/pizza-active-icon/${activeStepObject.stepLabel.replace(
                  /\s+/g,
                  ""
                )}.svg`}
                sx={{ width: "80", height: "80" }}
              />
            </Box> */}
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  lineHeight: "32px",
                  textAlign: { xs: "center", md: "left" },
                }}
                variant="h5"
                component="h2"
                // className="avatar-label"
              >
                {activeStepObject.stepLabel}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      {/* <Box>
        <Typography
          variant="p"
          display={"flex"}
          flexDirection="row"
          fontSize={"15px"}
          textAlign="right"
        >
          &nbsp;
          <p style={{ color: "red", fontWeight: "bold" }}>* &nbsp;</p> Fields are mandatory
        </Typography>
      </Box> */}

      <Box
        flex={1}
        sx={{
          pb: { xs: 2, md: 10, lg: 10, xl: 3 },
        }}
      >
        {questionsObject.map((question, index) => {
          if (
            question.stepId === activeStepObject.stepId &&
            !question.isSuggested &&
            !question.hideQuestion
          ) {
            switch (question.type) {
              case "text":
                return (
                  <TextQuestionComponent
                    key={index}
                    question={question}
                    questionsObject={questionsObject}
                    index={index}
                  />
                );
              case "number":
                return (
                  <NumberQuestionComponent
                    key={index}
                    question={question}
                    questionsObject={questionsObject}
                  />
                );
              case "select":
                return (
                  <SelectQuestionComponent
                    question={question}
                    key={index}
                    questionsObject={questionsObject}
                  />
                );
              case "multiSelect":
                return (
                  <>
                    {question?.skillMultiselect === true ? (
                      <>
                        <SkillMultiSelectQuestionComponent question={question} key={index} />
                      </>
                    ) : question?.dynamicMultiSelect ? (
                      <DynamicMultiSelectQuestionComponent key={index} question={question} />
                    ) : (
                      <MultiSelectQuestionComponent question={question} key={index} />
                    )}
                  </>
                );
              case "radio":
                return (
                  <RadioQuestionComponent
                    question={question}
                    questionsObject={questionsObject}
                    key={index}
                  />
                );
              default:
                return "";
            }
          }
        })}
      </Box>
    </Box>
  );
}
export default SingleStepForm;
