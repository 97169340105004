import React from "react";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import { color } from "@mui/system";
import LanguageSharpIcon from "@mui/icons-material/LanguageSharp";
import _ from "lodash";

function TooltipComponent({ message }) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Tooltip
      open={showTooltip}
      title={
        <Typography variant="p" component="p" color={"#fff"} fontSize={"18px"}>
          {_.isArray(message)
            ? message.map((msg, index) => {
                return (
                  <b key={index}>
                    {msg}
                    <LanguageSharpIcon
                      sx={{
                        borderRadius: "100%",
                        color: "lightgray",
                        ml: "1px",
                        pt: "5px",
                      }}
                    />
                  </b>
                );
              })
            : message}
        </Typography>
      }
      arrow
      placement="top-start"
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
    >
      <InfoIcon
        onClick={() => setShowTooltip(!showTooltip)}
        sx={{
          backgroundColor: "#000",
          borderRadius: "100%",
          color: "#ffc218",
          ml: "5px",
        }}
      />
    </Tooltip>
  );
}

export default TooltipComponent;
